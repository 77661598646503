import { Button, Icon, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import React, { Fragment, useState } from 'react'

import {
  LotacaoRetificacaoAtendimentoButtonModel,
  RetificacaoAtendimentoModel,
  RetificacaoAtendimentoOpcaoEnum,
} from './model-retificacaoAtendimento'
import { RemoveRegistroConfirmModal } from './RemoveRegistroConfirmModal'
import { RetificacaoAtendimentoModal } from './RetificacaoAtendimentoModal'

interface RetificacaoAtendimentoButtonProps {
  cidadaoId: ID
  dataAtendimento: Instant
  lotacao: LotacaoRetificacaoAtendimentoButtonModel
}

export function RetificacaoAtendimentoButton(props: RetificacaoAtendimentoButtonProps) {
  const { cidadaoId, dataAtendimento, lotacao } = props
  const alert = useAlert()
  const { acesso, profissional: profissionalAcesso } = useAcessoLotacaoOrEstagio()
  const [modalRetificacaoAtendimentoOpen, setModalRetificacaoAtendimentoOpen] = useState(false)
  const [modalRemoveRegistroOpen, setModalRemoveRegistroOpen] = useState(false)

  const isAcessoLotacaoCriadora =
    lotacao.profissional?.id === profissionalAcesso.id &&
    lotacao.equipe?.id === acesso.equipe?.id &&
    lotacao.cbo?.id === acesso.cbo?.id &&
    lotacao.unidadeSaude?.id === acesso.unidadeSaude?.id

  const isMesmoProfissionalCriadorOutraLotacao =
    lotacao.profissional?.id === profissionalAcesso.id && !isAcessoLotacaoCriadora

  const isRetificacaoAtendimentoDisabled = !isAcessoLotacaoCriadora || isMesmoProfissionalCriadorOutraLotacao

  const retificarAtendimentoTooltip = isAcessoLotacaoCriadora
    ? 'Retificar registro'
    : isMesmoProfissionalCriadorOutraLotacao
    ? 'É necessário acessar com a lotação que você realizou o registro para retificá-lo'
    : 'Somente a lotação responsável pelo registro pode retificá-lo'

  const handleModalClick = (e: React.MouseEvent) => e.stopPropagation()

  const handleModalRemoveRegistroOpen = () => setModalRemoveRegistroOpen(true)

  const handleModalRemoveRegistroClose = () => setModalRemoveRegistroOpen(false)

  const handleModalRemoveRegistroSubmit = () => {
    handleModalRemoveRegistroClose()
    handleRetificacaoAtendimentoModalClose()
    //TODO: @RNG Criar fluxo para possibilitar que o usuário inative um registro (#21645)
    alert('success', 'Registro removido com sucesso.')
  }

  const handleRetificacaoAtendimentoModalOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setModalRetificacaoAtendimentoOpen(true)
  }

  const handleRetificacaoAtendimentoModalClose = () => setModalRetificacaoAtendimentoOpen(false)

  const handleRetificacaoAtendimentoModalSubmit = (values: RetificacaoAtendimentoModel) => {
    if (values.tipo === RetificacaoAtendimentoOpcaoEnum.REMOVER_REGISTRO) {
      handleModalRemoveRegistroOpen()
    }
    //TODO: Criar tela de retificação para os atendimentos de procedimento - Seção subjetivo (#21393)
  }

  return (
    <Fragment>
      <RemoveRegistroConfirmModal
        open={modalRemoveRegistroOpen}
        onClick={handleModalClick}
        onCancelar={handleModalRemoveRegistroClose}
        onConfirmar={handleModalRemoveRegistroSubmit}
      />
      <RetificacaoAtendimentoModal
        cidadaoId={cidadaoId}
        dataAtendimento={dataAtendimento}
        open={modalRetificacaoAtendimentoOpen}
        onClick={handleModalClick}
        onCancelar={handleRetificacaoAtendimentoModalClose}
        onSubmit={handleRetificacaoAtendimentoModalSubmit}
      />
      <Tooltip text={retificarAtendimentoTooltip}>
        <Button
          size='small'
          skin='ghost'
          disabled={isRetificacaoAtendimentoDisabled}
          onClick={handleRetificacaoAtendimentoModalOpen}
        >
          <Icon icon='penOutline' size={1.5} />
        </Button>
      </Tooltip>
    </Fragment>
  )
}
