import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { getLotacaoResponsavel } from 'components/lotacao-responsavel/utils/utils-lotacaoResponsavel'
import { TitleGroup } from 'components/TitleGroup'
import { css } from 'emotion'
import { Maybe } from 'graphql/types.generated'
import React, { Fragment, useMemo } from 'react'

import { AtividadeColetivaFormModel } from '../model-atividadeColetiva'
import AtividadeColetivaReuniaoForm from './AtividadeColetivaReuniaoForm'

export const AtividadeColetivaReuniaoView = () => {
  const { acesso, profissional } = useAcessoLotacaoOrEstagio()

  const initialValues = useMemo<AtividadeColetivaFormModel>(
    () =>
      ({
        lotacaoResponsavel: getLotacaoResponsavel(
          profissional,
          acesso.cbo,
          acesso.equipe,
          acesso.unidadeSaude,
          acesso.id
        ),
      } as Maybe<AtividadeColetivaFormModel>),
    [acesso.cbo, acesso.equipe, acesso.id, acesso.unidadeSaude, profissional]
  )

  return (
    <Fragment>
      <Breadcrumb title='Registro de reunião de equipe' />
      <PageContent type='filled' style={styles.pageContent}>
        <TitleGroup title='Registro de reunião' style={styles.header} />
        <AtividadeColetivaReuniaoForm initialValues={initialValues} />
      </PageContent>
    </Fragment>
  )
}

const styles = {
  header: css`
    margin-top: 1rem;
    margin-bottom: 2rem;
  `,
  pageContent: css`
    padding-bottom: 3.5rem;
  `,
}
