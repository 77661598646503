/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Icon, Modal, ModalBody, Theme, useTheme, VFlow } from 'bold-ui'
import { AlertaSuporteDecisaoClinica } from 'hooks/sdc/model-sdc'

import { ReactComponent as SdcIcon } from '../../../images/suporte-decisao-clinica/health-system-outline.svg'
import { SdcDetalhesModalBody } from './SdcDetalhesModalBody'
import { SdcDetalhesModalSideMenu } from './SdcDetalhesModalSideMenu'

interface SdcDetalhesModalProps {
  isModalOpen: boolean
  onClose?(): void
  setAlertaSelecionado: (AlertaSuporteDecisaoClinica) => void
  alertaSelecionado: AlertaSuporteDecisaoClinica
  alertasSdc: AlertaSuporteDecisaoClinica[]
}

export const SdcDetalhesModal = (props: SdcDetalhesModalProps) => {
  const { isModalOpen, onClose, alertaSelecionado, alertasSdc, setAlertaSelecionado } = props

  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <Modal open={isModalOpen} onClose={onClose} size='auto' closeOnBackdropClick={false} style={styles.modalWrapper}>
      <HFlow style={styles.secoesWrapper}>
        <SdcDetalhesModalSideMenu
          alertas={alertasSdc}
          alertaSelecionado={alertaSelecionado}
          setAlertaSelecionado={setAlertaSelecionado}
        />
        <ModalBody style={styles.modalBodyStyle}>
          <div css={styles.detalhesWrapper}>
            <VFlow vSpacing={1} style={styles.headerWrapper}>
              <Heading level={1}>
                <HFlow alignItems='center'>
                  <Icon icon={SdcIcon} size={2.5} fill='primary' />
                  Suporte à Decisão Clínica
                </HFlow>
              </Heading>
              <Heading level={3} color='primary' fontWeight='bold'>
                {alertaSelecionado?.subtitulo}
              </Heading>
            </VFlow>
            <SdcDetalhesModalBody alertaSelecionado={alertaSelecionado} />
          </div>
        </ModalBody>
      </HFlow>
    </Modal>
  )
}

const createStyles = (theme: Theme) => ({
  modalWrapper: css`
    ::-webkit-scrollbar {
      display: !expanded && none;
      width: 0.25rem;
    }
    ::-webkit-scrollbar-track {
      background-color: ${theme.pallete.gray.c90};
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 1rem;
      background-color: ${theme.pallete.gray.c70};
    }
  `,
  secoesWrapper: css`
    height: 44rem;
    width: 62rem;
    @media (max-width: 1279px) {
      width: 56.625rem;
    }
  `,
  modalBodyStyle: css`
    padding: 2.5rem 0 0 1rem;
    width: 41.75rem;
    @media (max-width: 1279px) {
      width: 36.75rem;
    }
  `,
  detalhesWrapper: css`
    height: 31.3125rem;
    width: 40rem;
    text-align: justify;
  `,
  headerWrapper: css`
    margin-bottom: 1rem;
  `,
})
