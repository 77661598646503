/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Text, VFlow } from 'bold-ui'
import { AlertaSuporteDecisaoClinica } from 'hooks/sdc/model-sdc'
import { Fragment } from 'react'

interface SdcDetalhesModalBodyProps {
  alertaSelecionado: AlertaSuporteDecisaoClinica
}

export const SdcDetalhesModalBody = (props: SdcDetalhesModalBodyProps) => {
  const { alertaSelecionado } = props

  return (
    <Fragment>
      <VFlow vSpacing={0} style={styles.textoPrincipalWrapper}>
        <Text fontWeight='bold'>Recomendações</Text>
        {!!alertaSelecionado?.acompanhamento && (
          <HFlow hSpacing={0.25}>
            <Text fontWeight='bold'>Acompanhamento:</Text>
            <Text>{alertaSelecionado?.acompanhamento}</Text>
          </HFlow>
        )}
        {!!alertaSelecionado?.examesComplementares && (
          <HFlow hSpacing={0.25}>
            <Text fontWeight='bold'>Exames complementares:</Text>
            <Text>{alertaSelecionado?.examesComplementares}</Text>
          </HFlow>
        )}
        {alertaSelecionado?.conduta?.length > 0 && (
          <VFlow vSpacing={0}>
            <Text fontWeight='bold' style={styles.condutaTitle}>
              Conduta:
            </Text>
            <ul css={styles.listaCondutas}>
              <VFlow vSpacing={1}>
                {alertaSelecionado?.conduta?.map((conduta) => (
                  <li>{conduta}</li>
                ))}
              </VFlow>
            </ul>
          </VFlow>
        )}
        <Text>{alertaSelecionado?.observacao}</Text>
      </VFlow>
      {alertaSelecionado?.referencias?.length > 0 && (
        <VFlow vSpacing={0}>
          <Text fontWeight='bold'>Referências</Text>
          {alertaSelecionado?.referencias?.map((referencia) => (
            <Text fontStyle='italic'>{referencia}</Text>
          ))}
        </VFlow>
      )}
    </Fragment>
  )
}

const styles = {
  textoPrincipalWrapper: css`
    margin-bottom: 1rem;
  `,
  condutaTitle: css`
    margin-bottom: 0;
  `,
  listaCondutas: css`
    margin-top: 0;
  `,
}
