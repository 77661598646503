/** @jsx jsx */
import { jsx } from '@emotion/core'
import { HFlow, VFlow } from 'bold-ui'
import { PageContent } from 'components/layout/PageContent'
import { RelatoriosButtonGroup } from 'components/relatorios-buttons/RelatoriosButtonGroup'
import { usePagedTableProps } from 'components/table'
import { TitleGroup } from 'components/TitleGroup'
import { AcompanhamentoCidadaosVinculadosQuery, ArquivoStatusEnum, OrigemAtendimento } from 'graphql/types.generated'
import { useFormState } from 'react-final-form'
import { isUndefinedOrNull } from 'util/checks'
import {
  LinkVerEquipesVinculadasView,
  LinkVerEquipesVinculadasViewProps,
} from 'view/vinculacao-equipes/LinkVerEquipesVinculadasView'

import { convertAcompanhamentoCidadaosVinculadosQueryModelToRowModel } from '../../converter-acompanhamentoCidadaosVinculados'
import { AcompanhamentoCidadaosVinculadosFilterModel } from '../AcompanhamentoCidadaosVinculadosFilter'
import { TagOrigemAcompanhamentoCidadaosVinculados } from '../TagOrigemAcompanhamentoCidadaosVinculados'
import { AcompanhamentoCidadaosVinculadosPagedTable } from './AcompanhamentoCidadaosVinculadosPagedTable'
import { AcompanhamentoCidadaosVinculadosTableFilterView } from './AcompanhamentoCidadaosVinculadosTableFilterView'

interface AcompanhamentoCidadaosVinculadosTableViewProps extends Pick<LinkVerEquipesVinculadasViewProps, 'equipe'> {
  filtro: AcompanhamentoCidadaosVinculadosFilterModel
  data: AcompanhamentoCidadaosVinculadosQuery
  loading: boolean
  shouldRefetch: boolean
  statusRelatorio: ArquivoStatusEnum
  onChange(filter: AcompanhamentoCidadaosVinculadosFilterModel): void
  handleExportarCSVClick(): void
  handleImprimirClick(): void
}

export function AcompanhamentoCidadaosVinculadosTableView(props: AcompanhamentoCidadaosVinculadosTableViewProps) {
  const {
    filtro,
    loading,
    data,
    shouldRefetch,
    statusRelatorio,
    onChange,
    handleExportarCSVClick,
    handleImprimirClick,
  } = props

  const rows =
    data?.acompanhamentoCidadaosVinculados?.content?.map((row) =>
      convertAcompanhamentoCidadaosVinculadosQueryModelToRowModel(row)
    ) ?? []

  const pagedTableProps = usePagedTableProps({
    loading: loading && !data,
    result: data?.acompanhamentoCidadaosVinculados,
    onChange: onChange,
  })

  const { dirtySinceLastSubmit } = useFormState({
    subscription: { dirtySinceLastSubmit: true },
  })

  const showTable = !dirtySinceLastSubmit && !isUndefinedOrNull(data)

  return (
    showTable && (
      <PageContent loading={loading}>
        <VFlow vSpacing={1.5}>
          <TitleGroup title='Cidadãos encontrados' />
          <VFlow>
            {filtro && <AcompanhamentoCidadaosVinculadosTableFilterView filtro={filtro} />}
            <LinkVerEquipesVinculadasView {...props} />
            <AcompanhamentoCidadaosVinculadosPagedTable pagedTableProps={pagedTableProps} rows={rows} />
          </VFlow>
          <VFlow vSpacing={0.5}>
            <HFlow hSpacing={0.5} alignItems='center'>
              <TagOrigemAcompanhamentoCidadaosVinculados origem={OrigemAtendimento.PEC} /> - Cidadão sem cadastro nas
              fichas do CDS
            </HFlow>
            <HFlow hSpacing={0.5} alignItems='center'>
              <TagOrigemAcompanhamentoCidadaosVinculados origem={OrigemAtendimento.CDS} /> - Cidadão possui cadastro no
              CDS
            </HFlow>
          </VFlow>
          <RelatoriosButtonGroup
            shouldRefetch={shouldRefetch}
            statusRelatorio={statusRelatorio}
            numberOfElements={rows.length}
            onClickExportarCSV={handleExportarCSVClick}
            onClickexportarPDF={handleImprimirClick}
          />
        </VFlow>
      </PageContent>
    )
  )
}
