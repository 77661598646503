import { EquipeSelectModel, LocalDateRange } from 'components/form'
import { CiapCidSelectModel } from 'components/form/field/select/CiapCidSelectField/CiapCidSelectField'
import { GrupoCondicaoSelectModel } from 'components/form/field/select/GrupoCondicaoSelectField/GrupoCondicaoSelectField'
import { AgeRangeType, FaixaEtariaEnum, FaixaEtariaInput, GrupoCondicaoEnum, PageParams } from 'graphql/types.generated'

import { AcompanhamentoFilterModel } from '../models/model-acompanhamentoFilter'

export const ACOMPANHAMENTO_CONDICAO_SAUDE_ATMOSPHERE_PATH = 'acompanhamento-condicao-saude'

export enum AgeRangeFilter {
  TODAS_FAIXAS = 'TODAS_FAIXAS',
  CRIANCA = 'CRIANCA',
  ADOLESCENTE = 'ADOLESCENTE',
  ADULTO = 'ADULTO',
  IDOSO = 'IDOSO',
  OUTRA = 'OUTRA',
}

export interface AcompanhamentoCondicaoSaudeFormModel extends AcompanhamentoFilterModel {
  unidadeResponsavelId?: ID
  equipeResponsavelId?: ID
  equipeResponsavel?: EquipeSelectModel
  isCoordenador?: boolean
  microareas?: string[]
  foraarea?: boolean
  naoinformada?: boolean
  grupoCondicao?: GrupoCondicaoSelectModel[]
  problemasCondicoes?: CiapCidSelectModel[]
  ativoListaProblema?: boolean
  periodoUltimoAtendimento?: LocalDateRange
  // controle dos grupos rápidos
  isDiabetesCheck?: boolean
  isHipertensaoCheck?: boolean
  isObesidadeCheck?: boolean
  isGravidezCheck?: boolean
  pageParams?: PageParams
}

export const condicaoToFormRecord: Partial<Record<GrupoCondicaoEnum, keyof AcompanhamentoCondicaoSaudeFormModel>> = {
  [GrupoCondicaoEnum.DIABETES]: 'isDiabetesCheck',
  [GrupoCondicaoEnum.HIPERTENSAO_ARTERIAL]: 'isHipertensaoCheck',
  [GrupoCondicaoEnum.OBESIDADE]: 'isObesidadeCheck',
  [GrupoCondicaoEnum.GRAVIDEZ]: 'isGravidezCheck',
}

export interface AcompanhamentoCondicaoSaudeDto {
  idCidadao: ID
  nomeCidadao?: string
  nomeSocialCidadao?: string
  cpfCidadao?: string
  cnsCidadao?: string
  telefoneResidencial?: string
  telefoneCelular?: string
  telefoneContato?: string
  endereco?: string
  dataUltimoAtendimento?: Date
}

export const faixaEtariaRecord: Partial<Record<FaixaEtariaEnum, FaixaEtariaInput>> = {
  [FaixaEtariaEnum.CRIANCA]: { idadeMaxima: 10, tipoFaixaEtaria: AgeRangeType.ANOS },
  [FaixaEtariaEnum.ADOLESCENTE]: { idadeMinima: 11, idadeMaxima: 19, tipoFaixaEtaria: AgeRangeType.ANOS },
  [FaixaEtariaEnum.ADULTO]: { idadeMinima: 20, idadeMaxima: 59, tipoFaixaEtaria: AgeRangeType.ANOS },
  [FaixaEtariaEnum.IDOSO]: { idadeMinima: 60, tipoFaixaEtaria: AgeRangeType.ANOS },
}

export interface RelatorioOperacionalAuthorization {
  hasCriancaPermission: boolean
  hasGestantePermission: boolean
  hasHipertensaoPermission: boolean
}

export interface RelatoriosOperacionaisModel {
  tipoRelatorioOperacionalEnum: TipoRelatorioOperacionalEnum
}

export enum TipoRelatorioOperacionalEnum {
  CRIANCA = 'CRIANCA',
  GESTANTE = 'GESTANTE',
  RISCO_CARDIOVASCULAR = 'RISCO_CARDIOVASCULAR',
}

export const tipoRelatorioOperacionalLabel: Record<TipoRelatorioOperacionalEnum, string> = {
  [TipoRelatorioOperacionalEnum.CRIANCA]: 'Relatório operacional de Crianças menores de 5 anos',
  [TipoRelatorioOperacionalEnum.GESTANTE]: 'Relatório operacional de Gestante/Puérpera',
  [TipoRelatorioOperacionalEnum.RISCO_CARDIOVASCULAR]: 'Relatório operacional de Risco cardiovascular',
}

export const tipoRelatorioOperacionalAnalyticsLabel: Record<TipoRelatorioOperacionalEnum, string> = {
  [TipoRelatorioOperacionalEnum.CRIANCA]: 'relatorio_ope_acomp_cond_saude_with_crianca',
  [TipoRelatorioOperacionalEnum.GESTANTE]: 'relatorio_ope_acomp_cond_saude_with_gestante_puerpera',
  [TipoRelatorioOperacionalEnum.RISCO_CARDIOVASCULAR]: 'relatorio_ope_acomp_cond_saude_with_risco_cardiovascular',
}
