/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Heading, Text, VFlow } from 'bold-ui'
import { green } from 'bold-ui/lib/styles/colors'
import { Box } from 'components/Box'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { parseISO } from 'date-fns'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { isEmpty } from 'lodash'
import { useRef } from 'react'
import { dateAsYyyyMmDd } from 'util/date/formatDate'
import { MetaPath } from 'util/metaPath'

import { IvcfForm } from './ivcf-form/IvcfForm'
import { IvcfResultadoView } from './ivcf-resultado/IvcfResultadoView'
import { AtendimentoIvcfModel, IvcfModel } from './model-ivcf'

interface IvcfRootViewProps {
  name: MetaPath<AtendimentoIvcfModel>
  cachePath: MetaPath<IvcfModel>
}

export function IvcfRootView(props: IvcfRootViewProps) {
  const { name, cachePath } = props

  const {
    cidadao: { dataNascimento },
    atendimentoProfissional: { iniciadoEm },
  } = useAtendimentoContext()

  const {
    input: { value: cacheValue, onChange: updateCache },
  } = usePecField({ name: cachePath, subscription: { value: true } })

  const {
    input: { value, onChange },
  } = usePecField({ name, subscription: { value: true } })

  const isIvcfPreenchido = !isEmpty(value)

  const rootRef = useRef<HTMLDivElement>(null)

  return (
    <VFlow vSpacing={0.5}>
      <div ref={rootRef}>
        <Box style={styles.box}>
          <VFlow vSpacing={isIvcfPreenchido ? 1 : 0}>
            <Heading level={3} style={styles.heading}>
              <strong>Índice de Vulnerabilidade Clínico-Funcional</strong> (IVCF-20)
            </Heading>
            {!isIvcfPreenchido ? (
              <VFlow>
                <VFlow vSpacing={0.5}>
                  <Text>
                    As perguntas são direcionadas à pessoa idosa e devem ser confirmadas pelo familiar ou acompanhante,
                    desde que convivam com o idoso e estejam em condições de responder aos questionamentos. Nos idosos
                    incapazes de responder, utilizar as respostas do cuidador.
                  </Text>
                  <Alert type='info' inline>
                    Em geral o tempo de aplicação deste instrumento varia de 5 a 10 minutos.
                  </Alert>
                </VFlow>
                <IvcfForm
                  name={name}
                  cidadaoDataNascimento={dateAsYyyyMmDd(parseISO(dataNascimento))}
                  dataInicioAtendimento={dateAsYyyyMmDd(iniciadoEm)}
                  rootRef={rootRef}
                  onSubmit={onChange}
                  updateCache={updateCache}
                  initialValues={cacheValue}
                />
              </VFlow>
            ) : (
              <IvcfResultadoView ivcfPath={name} values={value} />
            )}
          </VFlow>
        </Box>
      </div>
    </VFlow>
  )
}

const styles = {
  heading: css`
    font-weight: normal;
  `,
  box: css`
    border-color: ${green.c60};
  `,
}
