/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Heading, HFlow, Icon, Text, Theme, Tooltip, useTheme, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { PopperButton, PopperControls } from 'components/popper'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { AlertaSuporteDecisaoClinica } from 'hooks/sdc/model-sdc'
import { useAlertasSdc } from 'hooks/sdc/useAlertasSdc'
import { ReactComponent as SvgHealthSystem } from 'images/suporte-decisao-clinica/health-system-outline.svg'
import { ReactComponent as SdcBoxIcon } from 'images/suporte-decisao-clinica/sdc-box.svg'
import { Fragment } from 'react'

import { SdcPopperAlert } from './SdcPopperAlert'

interface SdcPopperButtonProps {
  setIsDetalhesModalOpen: (boolean) => void
  setAlertaSelecionado: (AlertaSuporteDecisaoClinica) => void
}

export const SdcPopperButton = (props: SdcPopperButtonProps) => {
  const { setIsDetalhesModalOpen, setAlertaSelecionado } = props
  const theme = useTheme()
  const styles = createStyles(theme)

  const { analytics } = useFirebase()
  const { alertasSdc, hasAlertaNaoLido, marcarTodosAlertasComoLidos } = useAlertasSdc()

  const handleOpen = () => {
    analytics.logEvent('abrir_popper_SDC')
  }

  const handleClose = () => {
    marcarTodosAlertasComoLidos()
    analytics.logEvent('fechar_popper_SDC')
  }

  const renderPopper = ({ close }: PopperControls) => {
    const handleVerTudo = () => {
      analytics.logEvent('ver_tudo_popper_SDC')
      setIsDetalhesModalOpen(true)
      close()
    }
    const handleAlertaModalButtonClick = (alerta) => {
      setAlertaSelecionado(alerta)
      setIsDetalhesModalOpen(true)
      close()
    }

    return (
      <Box style={styles.box}>
        <Fragment>
          <HFlow alignItems='center' justifyContent='space-between' style={styles.header}>
            <Heading level={3}>Suporte à decisão clínica</Heading>
            <HFlow hSpacing={1}>
              <Tooltip text='Ver todas as orientações disparadas'>
                <Button size='small' skin='ghost' onClick={handleVerTudo}>
                  <HFlow hSpacing={0.5} justifyContent='flex-start' alignItems='center'>
                    <SdcBoxIcon />
                    <Text fontWeight='bold' fontSize={0.875}>
                      Ver tudo
                    </Text>
                  </HFlow>
                </Button>
              </Tooltip>
              <Button size='small' skin='ghost' onClick={() => close()}>
                <Tooltip text='Fechar'>
                  <Icon icon='timesDefault' />
                </Tooltip>
              </Button>
            </HFlow>
          </HFlow>
          <hr css={styles.divider} />
          <VFlow vSpacing={0} style={styles.alertsContainer}>
            {!alertasSdc || alertasSdc?.isEmpty() ? (
              <div css={styles.noAlertContainer}>
                <Text fontStyle='italic' css={styles.descriptionText}>
                  Ainda não existem notificações para este atendimento
                </Text>
              </div>
            ) : (
              <Fragment>
                {alertasSdc?.map((alerta: AlertaSuporteDecisaoClinica) => (
                  <SdcPopperAlert key={alerta.id} alerta={alerta} onAlertaClick={handleAlertaModalButtonClick} />
                ))}
              </Fragment>
            )}
          </VFlow>
        </Fragment>
      </Box>
    )
  }

  return (
    <Tooltip text={!hasAlertaNaoLido ? 'Suporte à decisão clínica' : 'Nova notificação do suporte à decisão clínica'}>
      <PopperButton
        skin='outline'
        kind='primary'
        size='small'
        positioningStrategy='fixed'
        placement='bottom-end'
        z-index={1}
        renderPopper={renderPopper}
        onOpen={handleOpen}
        onClose={handleClose}
        styleCallback={(isOpen) => css(styles.buttonSdc, isOpen && styles.buttonSdcActive)}
      >
        <HFlow hSpacing={0.5} justifyContent='flex-start' alignItems='center'>
          <SvgHealthSystem />
          <Text fontWeight='bold' fontSize={0.875} style={styles.textSdc}>
            SDC
          </Text>
          {hasAlertaNaoLido && <span css={styles.badgeSdc}></span>}
        </HFlow>
      </PopperButton>
    </Tooltip>
  )
}

const createStyles = (theme: Theme) => ({
  box: css`
    width: 27.875rem;
    height: 100%;
    max-height: 19.5rem;
    box-shadow: ${theme.shadows.outer[160]};
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-top: 0.5rem;
  `,
  header: css`
    padding: 1rem 1rem 1rem 1.5rem;
  `,
  divider: css`
    margin: 0;
    background-color: ${theme.pallete.divider};
  `,
  alertsContainer: css`
    overflow-y: auto;
    overflow-wrap: break-word;

    ::-webkit-scrollbar {
      width: 0.375rem;
      height: 6.25rem;
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${theme.pallete.gray.c70};
      border-radius: 5px;
    }
    ::-webkit-scrollbar-track {
      background: ${theme.pallete.surface.main};
    }
  `,
  noAlertContainer: css`
    display: flex;
    justify-content: center;
    padding: 1rem 1.5rem;
    color: ${theme.pallete.gray.c60};
  `,
  descriptionText: css`
    color: ${theme.pallete.gray.c40};
  `,
  buttonSdc: css`
    bottom: 0.5rem;
    &:not(:disabled):hover {
      background-color: ${theme.pallete.gray.c90};
    }
  `,
  buttonSdcActive: css`
    &,
    &:not(:disabled):hover {
      background-color: ${theme.pallete.gray.c90};
      box-shadow: ${theme.shadows.inner[10]};
    }
  `,
  textSdc: css`
    color: ${theme.pallete.primary.main};
  `,
  badgeSdc: css`
    position: absolute;
    top: -0.25rem;
    right: -0.25rem;
    width: 0.75rem;
    height: 0.75rem;
    background-color: ${theme.pallete.status.danger.c40};
    border-radius: 50%;
  `,
})
