import { Alert, HFlow, Link, Text, VFlow } from 'bold-ui'
import { useFlags } from 'config/useFlagsContext'
import { ReactComponent as AcompanhamentoCondicoesDeSaudeIcon } from 'images/side-menu/acompanhamentos.svg'
import { ReactComponent as BolsaFamiliaIcon } from 'images/side-menu/bolsa-familia.svg'
import { ReactComponent as BuscaAtiva } from 'images/side-menu/busca-ativa.svg'
import { ReactComponent as VideochamadaIcon } from 'images/side-menu/videochamadas.svg'
import React from 'react'

import { Carousel, CarouselItem } from './carousel/Corousel'
import { NewsGif } from './NewsGif'
import { NewsHeadingSection } from './NewsHeadingSection'
import { NewsImg } from './NewsImg'

export function NewsInternalPanelCincoTres() {
  const { CADASTRO_IMOVEL_ENABLED, SAUDE_INDIGENA_ENABLED, ACOMPANHAMENTO_CONDICAO_SAUDE_CSV } = useFlags()

  if (!process.env.REACT_APP_VERSION) {
    return (
      <Alert type='danger' inline>
        Nenhuma informação sobre versões anteriores está disponível.
      </Alert>
    )
  }

  return (
    <VFlow vSpacing={1}>
      <Text fontSize={0.875} fontWeight='bold'>
        Novidades:
      </Text>
      <HFlow hSpacing={5}>
        <VFlow vSpacing={0.2}>
          {ACOMPANHAMENTO_CONDICAO_SAUDE_CSV && (
            <Text fontSize={0.875} component='li'>
              Acompanhamento de condições de saúde
            </Text>
          )}
          <Text fontSize={0.875} component='li'>
            Busca ativa de vacinação
          </Text>
          {CADASTRO_IMOVEL_ENABLED && (
            <Text fontSize={0.875} component='li'>
              Cadastro de Imóveis e Famílias
            </Text>
          )}
          <Text fontSize={0.875} component='li'>
            Videochamada do profissional convidado adaptada para telefone celular
          </Text>
          <Text fontSize={0.875} component='li'>
            Videochamada do profissional convidado adaptada para tablet
          </Text>
          <Text fontSize={0.875} component='li'>
            Nome social do profissional
          </Text>
        </VFlow>
      </HFlow>

      {ACOMPANHAMENTO_CONDICAO_SAUDE_CSV && (
        <NewsHeadingSection
          title='Acompanhamento de condições de saúde'
          icon={
            <AcompanhamentoCondicoesDeSaudeIcon style={{ marginRight: '0.25rem', width: '32px', height: '32px' }} />
          }
          id='acompanhamento-de-condicoes-de-saude-5-3'
        >
          <VFlow vSpacing={0.5}>
            <Text fontSize={0.875} component='li'>
              Inclusão da exportação do relatório de busca em formato{' '}
              <Text fontSize={0.875} fontWeight='bold'>
                CSV
              </Text>
              , permitindo a manipulação dos dados.
            </Text>
            <Text fontSize={0.875} component='li'>
              Inclusão da visualização, de até 7 dias, dos relatórios que foram processados e que estão em
              processamento.
            </Text>
            <NewsGif
              src='/static/novidades/5-3_acompanhamento_condicoes_de_saude.gif'
              pausedSrc='/static/novidades/5-3_acompanhamento_condicoes_de_saude_paused.png'
              alt='Gif mostrando a exportação do relatório CSV'
            />
          </VFlow>
        </NewsHeadingSection>
      )}

      <NewsHeadingSection
        title='Busca ativa de vacinação'
        icon={<BuscaAtiva style={{ marginRight: '0.25rem', width: '32px', height: '32px' }} />}
        id='busca-ativa-vacinacao-5-3'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Implementação do Grupo-alvo de{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Adultos
            </Text>{' '}
            para Vacinas do calendário vacinal;
          </Text>
          <Text fontSize={0.875} component='li'>
            Implementação do Grupo-alvo de{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Puérperas com Gestantes
            </Text>{' '}
            para Vacinas do calendário vacinal;
          </Text>
          <Text fontSize={0.875} component='li'>
            Unificação dos Grupos-alvo de{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Gestantes e Puérperas
            </Text>{' '}
            para Vacinas do calendário vacinal;
          </Text>
          <Text fontSize={0.875} component='li'>
            Inclusão do filtro por{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Bairro, Tipo de Logradouro
            </Text>{' '}
            e{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Logradouro
            </Text>{' '}
            de residência do cidadão;
          </Text>
          <Text fontSize={0.875} component='li'>
            Inclusão da regra de{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Identidade de gênero
            </Text>{' '}
            nos relatórios.
          </Text>
          <NewsGif
            src='/static/novidades/5-3_busca_ativa_vacinacao_filtros_endereco.gif'
            pausedSrc='/static/novidades/5-3_busca_ativa_vacinacao_filtros_endereco_paused.png'
            alt='Gif mostrando os filtros de endereço na busca ativa de vacinação'
          />
        </VFlow>
      </NewsHeadingSection>

      {CADASTRO_IMOVEL_ENABLED && (
        <NewsHeadingSection title='Cadastro de Imóveis e Famílias' id='cadastro-imoveis-familias-5-3'>
          <VFlow vSpacing={0.5}>
            <Text fontSize={0.875} component='li'>
              A versão 5.3 agora permite cadastrar imóveis e famílias de forma mais completa e clara, facilitando a
              gestão dos cidadãos moradores de cada domicílio adscrito.
            </Text>
            <Text fontSize={0.875} component='li'>
              A funcionalidade de Acompanhamento do Território, no menu lateral, foi evoluída para permitir cadastrar e
              atualizar os imóveis e famílias, condições de moradia e responsabilidades de acompanhamento, tudo isso em
              uma única interface moderna e intuitiva, sem a necessidade de gerar várias Fichas CDS manualmente. O
              sistema as gerará automaticamente, apenas para que a sua produção seja consolidada em relatórios e enviada
              ao Centralizar Nacional.
            </Text>
            <Text fontSize={0.875} component='li'>
              Também será possível visualizar e corrigir inconsistências de cadastro através das novas telas, sem a
              necessidade de se aguardar o processamento após o Envio de Lotes de Fichas.
            </Text>
            <NewsImg
              src='/static/novidades/5-3_cadastro_ imoveis_familias.png'
              alt='Imagem exibindo a lista de famílias no cadastro de imóveis'
            />
          </VFlow>
        </NewsHeadingSection>
      )}

      <NewsHeadingSection
        title='Videochamada do profissional convidado adaptada para telefone celular'
        icon={<VideochamadaIcon style={{ marginRight: '0.25rem', width: '32px', height: '32px' }} />}
        id='videochamada-mobile-5-3'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Os componentes da videochamada foram adaptados para funcionar em telefones celular. O objetivo do módulo é
            possibilitar que o profissional convidado acesse a videochamada pelo telefone celular. Por outro lado, o
            profissional anfitrião ainda deve acessar a videochamada através do computador.
          </Text>
          <Text fontSize={0.875} component='li'>
            As funcionalidades da videochamada para telefone celular são:
          </Text>
          <ul>
            <Text fontSize={0.875} component='li'>
              Ativar e desativar camera;
            </Text>
            <Text fontSize={0.875} component='li'>
              Ativar e desativar microfone;
            </Text>
            <Text fontSize={0.875} component='li'>
              Chat.
            </Text>
          </ul>
          <Carousel>
            <CarouselItem
              image='/static/novidades/5-3_videochamada_mobile_sala_espera.png'
              alt='Imagem da sala de espera da videochamada adaptada para mobile'
            />
            <CarouselItem
              image='/static/novidades/5-3_videochamada_mobile_termos.png'
              alt='Imagem dos termos da videochamada adaptados para mobile'
            />
            <CarouselItem
              image='/static/novidades/5-3_videochamada_mobile_chamada.png'
              alt='Imagem da videochamada adaptada para mobile'
            />
          </Carousel>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection
        title='Videochamada do profissional convidado adaptada para tablet'
        icon={<VideochamadaIcon style={{ marginRight: '0.25rem', width: '32px', height: '32px' }} />}
        id='videochamada-tablet-5-3'
      >
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Os componentes da videochamada foram adaptados para funcionar corretamente em tablets. O objetivo do módulo
            é adaptar as telas da videochamada para tablet. Todas as funcionalidades da videochamada foram mantidas.
          </Text>
          <Carousel>
            <CarouselItem
              image='/static/novidades/5-3_videochamada_sala_espera_tablet.png'
              alt='Sala de espera no tablet'
            />
            <CarouselItem
              image='/static/novidades/5-3_termo_responsabilidade_tablet.png'
              alt='Termo de responsabilidade no tablet'
            />
            <CarouselItem image='/static/novidades/5-3_videochamada_tablet.png' alt='Videochamada no tablet' />
          </Carousel>
        </VFlow>
      </NewsHeadingSection>

      {SAUDE_INDIGENA_ENABLED && (
        <NewsHeadingSection title='Saúde indígena' id='saude-indigena-5-3'>
          <VFlow vSpacing={0.5}>
            <Text fontSize={0.875} component='li'>
              Com objetivo de tornar o PEC cada vez mais acessível aos diversos cenários existentes da saúde pública, o
              sistema foi atualizado para abranger as especificidades do contexto da saúde indígena!
            </Text>
            <ul>
              <Text fontSize={0.875} component='li'>
                <Text fontSize={0.875} fontWeight='bold'>
                  Cadastro do cidadão:
                </Text>{' '}
                Inclusão de novos campos, como "Nome tradicional", "Função social", "Aldeia de nascimento", entre
                outros. Além disso, o endereço também foi adaptado ao contexto, com campos para indicar DSEI, Polo base
                e aldeia;
              </Text>
              <Text fontSize={0.875} component='li'>
                <Text fontSize={0.875} fontWeight='bold'>
                  Busca do cidadão:
                </Text>{' '}
                Inclusão do campo "Etnia", com opção "Não indígena" e campos para buscar através do endereço de
                residência: "DSEI", "Polo Base" e "Aldeia";
              </Text>
              <Text fontSize={0.875} component='li'>
                <Text fontSize={0.875} fontWeight='bold'>
                  Cadastro de imóveis:
                </Text>{' '}
                Inclusão de novas opções nos campos que caracterizam os imóveis, como "tipo de domicílio", "material
                predominante na construção", "abastecimento de água" entre outros. O endereço também foi adaptado;
              </Text>
              <Text fontSize={0.875} component='li'>
                <Text fontSize={0.875} fontWeight='bold'>
                  Ficha de atendimento individual:
                </Text>{' '}
                Inclusão de opções de exames e condições mais avaliadas nos atendimentos.
              </Text>
            </ul>
            <Carousel>
              <CarouselItem image='/static/novidades/5-3_cadastro_si.png' alt='Imagem do cadastro do cidadão aldeado' />
              <CarouselItem image='/static/novidades/5-3_busca_si.png' alt='Imagem da busca do cidadão aldeado' />
              <CarouselItem image='/static/novidades/5-3_imovel_si.png' alt='Imagem do cadastro de imóvel SI' />
              <CarouselItem
                image='/static/novidades/5-3_imovel_si_condicoes.png'
                alt='Imagem do cadastro de imóvel SI - condições de moradia'
              />
            </Carousel>
          </VFlow>
        </NewsHeadingSection>
      )}

      <NewsHeadingSection
        title='Importação do bolsa família'
        icon={<BolsaFamiliaIcon style={{ marginRight: '0.25rem', width: '32px', height: '32px' }} />}
        id='bolsa-familia-5-3'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Agora será possível importar um arquivo com os beneficiários do bolsa família de uma vigência específica
            para que eles sejam identificados em seus prontuários.
          </Text>
          <Text fontSize={0.875} component='li'>
            O arquivo poderá ser baixado no{' '}
            <Link href='https://egestorab.saude.gov.br/' target='_blank'>
              e-Gestor
            </Link>
            e incluído para importação na funcionalidade de Importação do bolsa família, que pode ser acessada pelo menu
            lateral.
          </Text>
          <Text fontSize={0.875} component='li'>
            É possível importar arquivos de qualquer vigência, inclusive listas complementares.
          </Text>

          <NewsGif
            src='/static/novidades/5-3_importacao_beneficiarios_bolsa_familia.gif'
            pausedSrc='/static/novidades/5-3_importacao_beneficiarios_bolsa_familia_paused.png'
            alt='Gif mostrando importação do arquivo de beneficiários do bolsa família'
          />

          <Text fontSize={0.875} component='li'>
            Ao visualizar a folha de rosto de um cidadão que consta como beneficiário no arquivo importado da vigência
            mais atual, será apresentada uma sinalização de que ele é beneficiário do Programa Bolsa Família.
          </Text>

          <NewsImg
            src='/static/novidades/5-3_alerta_cidadao_beneficiario_bolsa_familia.png'
            alt='Imagem exibindo mensagem indicativa que o cidadão atendido é beneficiário do bolsa família'
          />
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection title='Nome social do profissional' id='nome-social-profissional-5-3'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            A tela de registro e edição de profissionais conta com o campo Nome social:
          </Text>
          <NewsImg
            src='/static/novidades/5-3_nome_social_cadastro_edicao.png'
            alt='Imagem exibindo a tela de cadastro e edição de profissionais com o campo nome social'
          />
          <Text fontSize={0.875} component='li'>
            A tela com as informações do profissional passará a exibir o nome social:
          </Text>
          <NewsImg
            src='/static/novidades/5-3_nome_social_informacoes_profissional.png'
            alt='Imagem exibindo a tela de informações do profissional com o nome social'
          />
          <Text fontSize={0.875} component='li'>
            Nos campos de busca caso um usuário busque pelo nome civil ou social do profissional o sistema deve retornar
            somente o nome social do profissional:
          </Text>
          <NewsImg
            src='/static/novidades/5-3_nome_social_busca.png'
            alt='Imagem exibindo a tela de busca com o nome social do profissional'
          />
          <Text fontSize={0.875} component='li'>
            Durante o atendimento todas as impressões passarão a contar com o nome social do profissional:
          </Text>
          <NewsImg
            src='/static/novidades/5-3_nome_social_impressao.png'
            alt='Imagem exibindo a impressão com o nome social do profissional'
          />
          <Text fontSize={0.875} component='li'>
            Os registro visualizados no atendimento também passarão a contar com o nome social do profissional:
          </Text>
          <NewsImg
            src='/static/novidades/5-3_nome_social_registro_visualizado.png'
            alt='Imagem exibindo o registro visualizado no atendimento com o nome social do profissional'
          />
          <Text fontSize={0.875} component='li'>
            O histórico do cidadão passará a exibir o nome social do profissional:
          </Text>
          <Carousel>
            <CarouselItem
              image='/static/novidades/5-3_nome_social_historico_cidadao.png'
              alt='Histórico do cidadão com o nome social do profissional'
            />
            <CarouselItem
              image='/static/novidades/5-3_nome_social_historico_cidadao_informacoes_administrativas.png'
              alt='Histórico do cidadão com o nome social do profissional nas informações administrativas'
            />
          </Carousel>
        </VFlow>
      </NewsHeadingSection>
    </VFlow>
  )
}
