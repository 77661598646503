import { AtividadeColetivaInput } from 'graphql/types.generated'

import { AtividadeColetivaFormModel } from '../model-atividadeColetiva'

export const convertAtividadeColetivaReuniaoFormModelToInput = (
  model: AtividadeColetivaFormModel
): AtividadeColetivaInput => ({
  tipoAtividadeEnum: model.tipoAtividade,
  lotacaoResponsavelId: model.lotacaoResponsavel?.lotacao.id,
  data: Date.parse(model.dataAtividade),
  turnoEnum: model.turno,
  numeroParticipantes: model.numeroParticipantes,
  unidadeSaudeLocalAtividadeId: model.localAtividade?.unidadeSaudeLocalAtividade?.id,
  outraLocalidade: model.localAtividade?.outraLocalidade,
  atividadeEducacaoPse: false,
  atividadeSaudePse: false,
  lotacaoEnvolvidaIds: model.lotacoesEnvolvidas?.map((item) => item.lotacao.id),
  temaReuniaoEnums: model.temasReuniao,
  anotacoes: model.anotacoes,
})
