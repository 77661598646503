import { green } from 'bold-ui/lib/styles/colors'
import { useFlags } from 'config/useFlagsContext'
import { useAcompanhamentoIdosoCardQuery, useUltimoIvcfQuery } from 'graphql/hooks.generated'
import React, { useEffect, useState } from 'react'

import { AtendimentoIvcfModel } from '../../objetivo/ivcf/model-ivcf'
import { ProntuarioCard } from '../ProntuarioCard'
import { AcompanhamentoIdosoCard } from './AcompanhamentoIdosoCard'

interface AcompanhamentoIdosoSectionProps {
  prontuarioId: ID
  cidadaoId: ID
  isFolhaDeRosto?: boolean
  ivcfAtendimentoAtual?: AtendimentoIvcfModel
}

export function AcompanhamentoIdosoSection(props: AcompanhamentoIdosoSectionProps) {
  const { prontuarioId, cidadaoId, isFolhaDeRosto = false, ivcfAtendimentoAtual } = props
  const [canRender, setCanRender] = useState(false)
  const { IVCF_ENABLED } = useFlags()

  const { data, loading } = useAcompanhamentoIdosoCardQuery({
    variables: {
      prontuarioId,
      cidadaoId,
    },
  })

  const { data: ultimoIvcf, loading: loadingUltimoIvcf } = useUltimoIvcfQuery({
    variables: {
      prontuarioId,
    },
    skip: !IVCF_ENABLED,
  })

  useEffect(() => {
    if (data?.ultimaAvaliacaoMultidimensional || ultimoIvcf?.ultimoIvcf || ivcfAtendimentoAtual) setCanRender(true)
  }, [data, ivcfAtendimentoAtual, ultimoIvcf])

  const tooltipText = IVCF_ENABLED
    ? 'Nenhuma avaliação multidimensional ou IVCF-20 registrado'
    : 'Nenhuma avaliação multidimensional registrada'

  return (
    <ProntuarioCard
      title='Acompanhamento do idoso'
      titleBackgroundColor={green.c90}
      tooltip={!canRender && tooltipText}
    >
      <AcompanhamentoIdosoCard
        data={data}
        ultimoIvcf={ultimoIvcf?.ultimoIvcf}
        loading={loading || loadingUltimoIvcf}
        canRender={canRender}
        isFolhaDeRosto={isFolhaDeRosto}
        ivcfAtendimentoAtual={ivcfAtendimentoAtual}
      />
    </ProntuarioCard>
  )
}
