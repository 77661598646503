import { PraticaSaudeEnum, TipoAtividadeEnum } from 'graphql/types.generated'

import { AtividadeColetivaFormModel, PRATICAS_SAUDE_PNCT } from '../model-atividadeColetiva'

export const isOnlyEducacaoPseSelected = (model: { atividadeEducacaoPse: boolean; atividadeSaudePse: boolean }) =>
  model.atividadeEducacaoPse && !model.atividadeSaudePse

export const isPraticaSaudeDisabled = (model: AtividadeColetivaFormModel) =>
  model.tipoAtividade === TipoAtividadeEnum.EDUCACAO_EM_SAUDE ||
  model.tipoAtividade === TipoAtividadeEnum.MOBILIZACAO_SOCIAL

export const isProcedimentoDisabled = (model: AtividadeColetivaFormModel) =>
  !model.praticasSaude?.includes(PraticaSaudeEnum.OUTRO_PROCEDIMENTO_COLETIVO)

export const getParticipanteProfExistsMsg = (envolvidoResp: string) =>
  `Participante deve ser diferente do profissional ${envolvidoResp}.`

export const getParticipanteProfCpfCnsExistsMsg = (cpfCns: string, envolvidoResp: string) =>
  `${cpfCns} informado deve ser diferente do CPF do profissional ${envolvidoResp}.`

export const isCidadaosParticipantesRequired = (tipoAtividade: TipoAtividadeEnum) =>
  tipoAtividade === TipoAtividadeEnum.AVALIACAO_PROCEDIMENTO_COLETIVO ||
  tipoAtividade === TipoAtividadeEnum.ATENDIMENTO_EM_GRUPO

export const isPraticaSaudePcnt = (praticasSaude: PraticaSaudeEnum[]) =>
  praticasSaude?.some((item) => PRATICAS_SAUDE_PNCT.includes(item))
