import { Heading, HFlow, Icon, Modal, ModalBody, ModalFooter, ModalProps, Text, VFlow } from 'bold-ui'
import { FooterButton } from 'components/footer-button'
import React from 'react'

interface RemoveRegistroConfirmModalProps extends ModalProps {
  onCancelar(): void
  onConfirmar(): void
}

export function RemoveRegistroConfirmModal(props: RemoveRegistroConfirmModalProps) {
  const { onCancelar, onConfirmar, ...rest } = props
  return (
    <Modal size='small' onClose={onCancelar} {...rest}>
      <ModalBody>
        <VFlow>
          <Heading level={2}>
            <HFlow alignItems='center'>
              <Icon icon='exclamationTriangleOutline' size={3} fill='danger' />
              Deseja realmente remover esse registro do prontuário do cidadão?
            </HFlow>
          </Heading>
          <Text>
            A informação de que esse registro foi removido será enviada para a base nacional e para a RNDS. Os registros
            removidos poderão ser visualizados na Trilha de Auditoria do PEC e-SUS APS
          </Text>
        </VFlow>
      </ModalBody>
      <ModalFooter>
        <HFlow justifyContent='flex-end'>
          <FooterButton kind='normal' onClick={onCancelar} size='medium'>
            Cancelar
          </FooterButton>
          <FooterButton kind='danger' onClick={onConfirmar} size='medium'>
            Remover
          </FooterButton>
        </HFlow>
      </ModalFooter>
    </Modal>
  )
}
