/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Alert, HFlow, Icon, Text, Tooltip, useTheme, VFlow } from 'bold-ui'
import { ColorScale } from 'bold-ui/lib/styles/colors'
import { Ellipsis } from 'components/Ellipsis'
import { useFlags } from 'config/useFlagsContext'
import { format } from 'date-fns'
import { css } from 'emotion'
import { AcompanhamentoIdosoCardQuery, RiscoVulnerabilidadeIvcfEnum, UltimoIvcfQuery } from 'graphql/types.generated'
import { Fragment } from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { humanizeDate } from 'util/date/humanize-date'

import { AtendimentoIvcfModel, IVCF_RESULTADOS, IVCF_SUBGRUPOS } from '../../objetivo/ivcf/model-ivcf'
import { ContentCardSection } from '../components/ContentCardSection'

interface AcompanhamentoIdosoCardProps {
  data: AcompanhamentoIdosoCardQuery
  loading: boolean
  canRender: boolean
  isFolhaDeRosto: boolean
  ivcfAtendimentoAtual?: AtendimentoIvcfModel
  ultimoIvcf: UltimoIvcfQuery['ultimoIvcf']
}

const humanizedDate = (data: Instant) => {
  const humanized = humanizeDate(data)
  return humanized === 'Hoje' ? humanized : 'Há ' + humanized
}

const renderAvalMulti = (data: Instant) => (
  <VFlow vSpacing={0.1}>
    <Text fontWeight='bold'>Última avaliação multidimensional</Text>
    {isUndefinedOrNull(data) ? '-' : `${format(data, 'dd/MM/yyyy')} | ${humanizedDate(data)}`}
  </VFlow>
)

export function AcompanhamentoIdosoCard(props: AcompanhamentoIdosoCardProps) {
  const { data, loading, canRender, isFolhaDeRosto, ivcfAtendimentoAtual, ultimoIvcf } = props

  const theme = useTheme()
  const { IVCF_ENABLED } = useFlags()

  const renderIvcf = (
    riscoVulnerabilidade?: RiscoVulnerabilidadeIvcfEnum,
    resultado?: number,
    dimensoesAlteradas?: string,
    dataResultado?: Instant,
    atendimentoProfissional?: UltimoIvcfQuery['ultimoIvcf']['atendimentoProfissional']
  ) => {
    const color = riscoVulnerabilidade ? IVCF_RESULTADOS[riscoVulnerabilidade].colorScale : theme.pallete.primary
    const classes = createStyles(color)
    const text = !(riscoVulnerabilidade && resultado != null)
      ? 'Não registrado'
      : `${IVCF_RESULTADOS[riscoVulnerabilidade].longTitle} | 
          ${resultado} ${resultado === 1 ? 'ponto' : 'pontos'}`
    const registradoEm = dataResultado
      ? `Registrado em ${format(dataResultado, 'dd/MM/yyyy')} | ${humanizedDate(dataResultado)}`
      : null

    return (
      <VFlow vSpacing={0.5}>
        <Text>
          <Text fontWeight='bold'>Índice de Vulnerabilidade Clínico-Funcional </Text>
          <div css={{ display: 'inline-block' }}>
            <HFlow alignItems='center' hSpacing={0.5}>
              <Text>(IVCF-20)</Text>
              {!isFolhaDeRosto && ivcfAtendimentoAtual && (
                <Tooltip text='Registrado agora'>
                  <Icon icon='clockOutline' color={theme.pallete.primary.c40} size={1} />
                </Tooltip>
              )}
            </HFlow>
          </div>
          {dataResultado && !isFolhaDeRosto && !ivcfAtendimentoAtual && <HFlow>{registradoEm}</HFlow>}
        </Text>
        <Alert type='info' inline styles={{ wrapper: classes.wrapper, container: classes.container }}>
          {text}
        </Alert>
        {dimensoesAlteradas != null && (
          <VFlow vSpacing={0.1}>
            <Text fontWeight='bold'>Dimensões alteradas:</Text>
            <Text>{dimensoesAlteradas || 'Nenhuma alteração'}.</Text>
          </VFlow>
        )}
        {dataResultado && atendimentoProfissional && isFolhaDeRosto && (
          <Ellipsis maxLines={3}>
            {registradoEm} por {atendimentoProfissional.lotacao.profissional.nome.truncate(16)} |{' '}
            {atendimentoProfissional?.lotacao?.cbo?.nome.capitalize()}
          </Ellipsis>
        )}
      </VFlow>
    )
  }

  return (
    <ContentCardSection isLoading={loading} messageLoading='Carregando dados do idoso' canRender={canRender}>
      <VFlow>
        {IVCF_ENABLED && (
          <Fragment>
            {!isFolhaDeRosto && ivcfAtendimentoAtual
              ? renderIvcf(
                  ivcfAtendimentoAtual?.resultado,
                  ivcfAtendimentoAtual?.totalPontos,
                  ivcfAtendimentoAtual.dimensoesAlteradas
                    .map((dimensaoAlterada) => IVCF_SUBGRUPOS[dimensaoAlterada].label)
                    .join('; ')
                )
              : renderIvcf(
                  ultimoIvcf?.riscoVulnerabilidade,
                  ultimoIvcf?.resultado,
                  ultimoIvcf?.dimensoesAlteradas,
                  ultimoIvcf?.dataResultado,
                  ultimoIvcf?.atendimentoProfissional
                )}
          </Fragment>
        )}
        {renderAvalMulti(data.ultimaAvaliacaoMultidimensional)}
      </VFlow>
    </ContentCardSection>
  )
}

const createStyles = (color: ColorScale) => ({
  wrapper: css`
    background-color: ${color.c90};
    border: 1px solid ${color.c60};
    color: ${color.c40};
    display: flex;
  `,
  container: css`
    align-items: center;
  `,
})
