import { CidadaoSelectFieldModel } from 'components/form'
import { cns, cpf, createValidator, empty, ErrorObject, maxLength, nome, range, required } from 'util/validation'

import {
  getParticipanteProfCpfCnsExistsMsg,
  getParticipanteProfExistsMsg,
} from '../../saude/utils-atividadeColetivaSaude'
import {
  CidadaoSemCadastroModel,
  InfoParticipanteModel,
  ParticipanteEditableTableModel,
  ProfissionalCpfCnsIdModel,
} from './ParticipanteEditableTable'

const infoParticipanteValidator = createValidator<InfoParticipanteModel>({
  altura: range(20, 250),
  peso: range(0.5, 500),
})

const cidadaoSemCadastroValidator = (
  allItems: ParticipanteEditableTableModel[],
  profissionalResponsavel: ProfissionalCpfCnsIdModel,
  profissionaisEnvolvidos: ProfissionalCpfCnsIdModel[]
) =>
  createValidator<CidadaoSemCadastroModel>(
    {
      nome: [required, nome(), maxLength(70)],
      dataNascimento: required,
      sexo: required,
      cns: cns,
      cpf: cpf,
    },
    (model: CidadaoSemCadastroModel, errors: ErrorObject<CidadaoSemCadastroModel>) => {
      if (allItems.some((participante) => hasSameCpf(participante.cidadao ?? participante.cidadaoSemCadastro, model))) {
        errors.cpf = 'CPF informado já foi cadastrado'
      } else if (hasSameCpf(profissionalResponsavel, model)) {
        errors.cpf = getParticipanteProfCpfCnsExistsMsg('CPF', 'responsável')
      } else if (profissionaisEnvolvidos?.some((profissional) => hasSameCpf(profissional, model))) {
        errors.cpf = getParticipanteProfCpfCnsExistsMsg('CPF', 'envolvido')
      }

      if (allItems.some((participante) => hasSameCns(participante.cidadao ?? participante.cidadaoSemCadastro, model))) {
        errors.cns = 'CNS informado já foi cadastrado'
      } else if (hasSameCns(profissionalResponsavel, model)) {
        errors.cns = getParticipanteProfCpfCnsExistsMsg('CNS', 'responsável')
      } else if (profissionaisEnvolvidos?.some((profissional) => hasSameCns(profissional, model))) {
        errors.cns = getParticipanteProfCpfCnsExistsMsg('CNS', 'envolvido')
      }

      return errors
    }
  )

export const validateCidadaoParticipante = (
  model: CidadaoSelectFieldModel,
  allItems: ParticipanteEditableTableModel[],
  profissionalResponsavel: ProfissionalCpfCnsIdModel,
  profissionaisEnvolvidos: ProfissionalCpfCnsIdModel[]
) => {
  if (
    allItems?.some((participante) => hasSameCpfCnsId(participante?.cidadao ?? participante?.cidadaoSemCadastro, model))
  ) {
    return 'Cidadão informado já foi cadastrado'
  } else if (profissionaisEnvolvidos?.some((profissional) => hasSameCpfCnsId(profissional, model))) {
    return getParticipanteProfExistsMsg('envolvido')
  } else if (hasSameCpfCnsId(profissionalResponsavel, model)) {
    return getParticipanteProfExistsMsg('responsável')
  }

  return undefined
}

export const participanteEditableTableValidator = (
  allItems: ParticipanteEditableTableModel[],
  profissionalResponsavel: ProfissionalCpfCnsIdModel,
  profissionaisEnvolvidos: ProfissionalCpfCnsIdModel[]
) => {
  const validateCidadaoSemCadastro = cidadaoSemCadastroValidator(
    allItems,
    profissionalResponsavel,
    profissionaisEnvolvidos
  )

  return createValidator<ParticipanteEditableTableModel>(
    {
      infoParticipante: infoParticipanteValidator,
    },
    (model: ParticipanteEditableTableModel, errors: ErrorObject<ParticipanteEditableTableModel>) => {
      if (model?.isCidadaoSemCadastroFormOpen) {
        errors.cidadaoSemCadastro = validateCidadaoSemCadastro(model?.cidadaoSemCadastro)
        errors.cidadao = empty(model?.cidadao)
      } else {
        errors.cidadaoSemCadastro = empty(model?.cidadaoSemCadastro)
        errors.cidadao =
          required(model?.cidadao) ||
          validateCidadaoParticipante(model?.cidadao, allItems, profissionalResponsavel, profissionaisEnvolvidos)
      }

      return errors
    }
  )
}

type HasId = { id?: ID }
type HasCpf = { cpf?: string }
type HasCns = { cns?: string }

const hasSameId = (item: HasId, model: HasId) => model?.id && model.id === item?.id
const hasSameCpf = (item: HasCpf, model: HasCpf) => model?.cpf && model.cpf === item?.cpf
const hasSameCns = (item: HasCns, model: HasCns) => model?.cns && model.cns === item?.cns
const hasSameCpfCnsId = (item: HasCns & HasCpf & HasId, model: HasCns & HasCpf & HasId) =>
  hasSameCpf(item, model) || hasSameCns(item, model) || hasSameId(item, model)
